import React from 'react';
import { useDropzone } from 'react-dropzone';
import './RecipientDetails.css';

const RecipientDetails = ({ recipientDetails, handleInputChange, errors }) => {
  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      const reader = new FileReader();
      reader.onload = (e) => {
        handleInputChange('idPicture', e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: false
  });

  return (
    <div className="RecipientDetails">
      <h3>Recipient Details</h3>
      <input
        type="text"
        placeholder="First Name"
        value={recipientDetails.firstName || ''}
        onChange={(e) => handleInputChange('firstName', e.target.value)}
      />
      {errors.recipientFirstName && <span className="error">{errors.recipientFirstName}</span>}
      <input
        type="text"
        placeholder="Last Name"
        value={recipientDetails.lastName || ''}
        onChange={(e) => handleInputChange('lastName', e.target.value)}
      />
      {errors.recipientLastName && <span className="error">{errors.recipientLastName}</span>}
      <input
        type="email"
        placeholder="Email"
        value={recipientDetails.email || ''}
        onChange={(e) => handleInputChange('email', e.target.value)}
      />
      {errors.recipientEmail && <span className="error">{errors.recipientEmail}</span>}
      <input
        type="tel"
        placeholder="Phone Number"
        value={recipientDetails.PhoneNumber || ''}
        onChange={(e) => handleInputChange('PhoneNumber', e.target.value)}
      />
      {errors.recipientPhoneNumber && <span className="error">{errors.recipientPhoneNumber}</span>}

      <label htmlFor="recipientIdType">ID Type</label>
      <select
        id="recipientIdType"
        value={recipientDetails.idType || ''}
        onChange={(e) => handleInputChange('idType', e.target.value)}
        className="id-type-select"
      >
        <option value="">Select ID Type</option>
        <option value="passport">Passport</option>
        <option value="driverLicense">Driver's License</option>
        <option value="nationalId">National ID</option>
      </select>
      {errors.recipientIdType && <span className="error">{errors.recipientIdType}</span>}
      
      <label className="id-upload-label">ID Picture</label>
      <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
        <input {...getInputProps()} />
        {recipientDetails.idPicture ? (
          <img src={recipientDetails.idPicture} alt="ID Preview" className="id-preview" />
        ) : (
          <p>Drag 'n' drop your ID picture here, or click to select a file</p>
        )}
      </div>
      {errors.recipientIdPicture && <span className="error">{errors.recipientIdPicture}</span>}
    </div>
  );
};

export default RecipientDetails;