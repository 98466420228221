import React, { useState, useEffect } from 'react';
import { accountApi } from '../../api/api';
import './AccountSection.css';

const AccountSection = () => {
  const [activeTab, setActiveTab] = useState('personal');
  const [personalInfo, setPersonalInfo] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: ''
  });
  const [address, setAddress] = useState({
    streetAddress: '',
    apartmentNumber: '',
    city: '',
    state: '',
    postalCode: '',
    country: ''
  });
  const [passwords, setPasswords] = useState({
    currentPassword: '',
    newPassword: '',
    confirmPassword: ''
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  useEffect(() => {
    fetchAccountInfo();
  }, []);

  const fetchAccountInfo = async () => {
    setIsLoading(true);
    try {
      const response = await accountApi.getAccountInfo();
      const { firstName, lastName, email, phoneNumber, address } = response.data;
      setPersonalInfo({ firstName, lastName, email, phoneNumber });
      setAddress(address || {});
    } catch (err) {
      setError('Failed to fetch account information. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePersonalInfoChange = (e) => {
    setPersonalInfo({ ...personalInfo, [e.target.name]: e.target.value });
  };

  const handleAddressChange = (e) => {
    setAddress({ ...address, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswords({ ...passwords, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    if (activeTab === 'personal') {
      if (!personalInfo.firstName || !personalInfo.lastName || !personalInfo.email || !personalInfo.phoneNumber) {
        setError('Please fill in all fields.');
        return false;
      }
      if (!/^\S+@\S+\.\S+$/.test(personalInfo.email)) {
        setError('Please enter a valid email address.');
        return false;
      }
    } else if (activeTab === 'address') {
      if (!address.streetAddress || !address.city || !address.state || !address.postalCode || !address.country) {
        setError('Please fill in all required fields.');
        return false;
      }
    } else if (activeTab === 'password') {
      if (!passwords.currentPassword || !passwords.newPassword || !passwords.confirmPassword) {
        setError('Please fill in all password fields.');
        return false;
      }
      if (passwords.newPassword !== passwords.confirmPassword) {
        setError('New password and confirm password do not match.');
        return false;
      }
      if (passwords.newPassword.length < 8) {
        setError('New password must be at least 8 characters long.');
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);

    if (!validateForm()) {
      return;
    }

    setIsLoading(true);

    try {
      if (activeTab === 'personal' || activeTab === 'address') {
        await accountApi.updateAccountInfo({
          ...personalInfo,
          address
        });
        alert('Information updated successfully!');
      } else if (activeTab === 'password') {
        setShowConfirmModal(true);
      }
    } catch (err) {
      setError('An error occurred. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmPasswordChange = async () => {
    setShowConfirmModal(false);
    setIsLoading(true);

    try {
      await accountApi.changePassword({
        currentPassword: passwords.currentPassword,
        newPassword: passwords.newPassword
      });
      alert('Password changed successfully!');
      setPasswords({ currentPassword: '', newPassword: '', confirmPassword: '' });
    } catch (err) {
      setError('An error occurred while changing the password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="AccountSection">
      <h2>Account Management</h2>
      <div className="account-tabs">
        <button
          className={activeTab === 'personal' ? 'active' : ''}
          onClick={() => setActiveTab('personal')}
        >
          Personal Information
        </button>
        <button
          className={activeTab === 'password' ? 'active' : ''}
          onClick={() => setActiveTab('password')}
        >
          Change Password
        </button>
        <button
          className={activeTab === 'address' ? 'active' : ''}
          onClick={() => setActiveTab('address')}
        >
          Manage Address
        </button>
      </div>

      {error && <div className="error-message">{error}</div>}

      <form onSubmit={handleSubmit}>
        {activeTab === 'personal' && (
          <div className="tab-content">
            <h3>Personal Information</h3>
            <div className="form-group">
              <label htmlFor="firstName">First Name</label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={personalInfo.firstName}
                onChange={handlePersonalInfoChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={personalInfo.lastName}
                onChange={handlePersonalInfoChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                value={personalInfo.email}
                onChange={handlePersonalInfoChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="phoneNumber">Phone</label>
              <input
                type="tel"
                id="phoneNumber"
                name="phoneNumber"
                value={personalInfo.phoneNumber}
                onChange={handlePersonalInfoChange}
                required
              />
            </div>
          </div>
        )}

        {activeTab === 'password' && (
          <div className="tab-content">
            <h3>Change Password</h3>
            <div className="form-group">
              <label htmlFor="currentPassword">Current Password</label>
              <input
                type="password"
                id="currentPassword"
                name="currentPassword"
                value={passwords.currentPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="newPassword">New Password</label>
              <input
                type="password"
                id="newPassword"
                name="newPassword"
                value={passwords.newPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirmPassword">Confirm New Password</label>
              <input
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                value={passwords.confirmPassword}
                onChange={handlePasswordChange}
                required
              />
            </div>
          </div>
        )}

        {activeTab === 'address' && (
          <div className="tab-content">
            <h3>Manage Address</h3>
            <div className="form-group">
              <label htmlFor="streetAddress">Street Address</label>
              <input
                type="text"
                id="streetAddress"
                name="streetAddress"
                value={address.streetAddress}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="apartmentNumber">Apartment Number</label>
              <input
                type="text"
                id="apartmentNumber"
                name="apartmentNumber"
                value={address.apartmentNumber}
                onChange={handleAddressChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="city">City</label>
              <input
                type="text"
                id="city"
                name="city"
                value={address.city}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="state">State</label>
              <input
                type="text"
                id="state"
                name="state"
                value={address.state}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="postalCode">Postal Code</label>
              <input
                type="text"
                id="postalCode"
                name="postalCode"
                value={address.postalCode}
                onChange={handleAddressChange}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="country">Country</label>
              <input
                type="text"
                id="country"
                name="country"
                value={address.country}
                onChange={handleAddressChange}
                required
              />
            </div>
          </div>
        )}

        <button type="submit" className="submit-btn" disabled={isLoading}>
          {isLoading ? 'Saving...' : 'Save Changes'}
        </button>
      </form>

      {showConfirmModal && (
        <div className="modal">
          <div className="modal-content">
            <h3>Confirm Password Change</h3>
            <p>Are you sure you want to change your password?</p>
            <div className="modal-actions">
              <button onClick={handleConfirmPasswordChange}>Yes, Change Password</button>
              <button onClick={() => setShowConfirmModal(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default AccountSection;