import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDollarSign } from '@fortawesome/free-solid-svg-icons';
import './BillingDetails.css';

const BillingDetails = ({ billingDetails, handleInputChange, errors, calculatedAmount }) => {
  return (
    <div className="BillingDetails">
      <div className="step-content">
        <h3>Billing</h3>
        <div className="amount-container">
          <FontAwesomeIcon icon={faDollarSign} className="billing-icon" />
          <div className="amount-content">
            <h4>Amount Issued</h4>
            <p>The amount that will be billed is: {calculatedAmount} USD</p>
          </div>
        </div>
        <input
          type="text"
          placeholder="Card Number"
          value={billingDetails.cardNumber || ''}
          onChange={(e) => handleInputChange('cardNumber', e.target.value)}
        />
        {errors.billingCardNumber && <span className="error">{errors.billingCardNumber}</span>}
        <input
          type="text"
          placeholder="Card Holder Name"
          value={billingDetails.cardHolderName || ''}
          onChange={(e) => handleInputChange('cardHolderName', e.target.value)}
        />
        {errors.billingCardHolderName && <span className="error">{errors.billingCardHolderName}</span>}
        <div className="card-details">
          <input
            type="text"
            placeholder="Expiration Date"
            value={billingDetails.expirationDate || ''}
            onChange={(e) => handleInputChange('expirationDate', e.target.value)}
          />
          <input
            type="text"
            placeholder="CVV"
            value={billingDetails.cvv || ''}
            onChange={(e) => handleInputChange('cvv', e.target.value)}
          />
        </div>
        {errors.billingExpirationDate && <span className="error">{errors.billingExpirationDate}</span>}
        {errors.billingCvv && <span className="error">{errors.billingCvv}</span>}
      </div>
    </div>
  );
};

export default BillingDetails;