import React from 'react';
import './ProgressBar.css';

const ProgressBar = ({ steps, currentStep }) => {
  return (
    <div className="ProgressBar">
      {steps.map((step, index) => (
        <div
          key={index}
          className={`progress-step ${index === currentStep ? 'active' : ''} ${index < currentStep ? 'completed' : ''}`}
        >
          <div className="step-indicator"></div>
          <span className="step-name">{step}</span>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;