import React from 'react';
import './NavigationButtons.css';

const NavigationButtons = ({ currentStep, totalSteps, handlePrevious, handleNext, handleSubmit, isSubmitting }) => {
  return (
    <div className="NavigationButtons">
      {currentStep > 0 && (
        <button className="btn btn-secondary" onClick={handlePrevious} disabled={isSubmitting}>Previous</button>
      )}
      {currentStep < totalSteps - 1 ? (
        <button className="btn btn-primary" onClick={handleNext} disabled={isSubmitting}>Next</button>
      ) : (
        <button className="btn btn-success" onClick={handleSubmit} disabled={isSubmitting}>
          {isSubmitting ? 'Submitting...' : 'Submit Order'}
        </button>
      )}
    </div>
  );
};

export default NavigationButtons;