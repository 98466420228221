import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

// Fix for default marker icon
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

function MapUpdater({ center }) {
  const map = useMap();
  useEffect(() => {
    if (center && center[0] !== null && center[1] !== null) {
      map.setView(center, 13);
    }
  }, [center, map]);
  return null;
}

const MapComponent = ({ pickupLocation, dropoffLocation }) => {
  const defaultCenter = [0, 0];
  const center = (pickupLocation && pickupLocation[0] !== null && pickupLocation[1] !== null) ? pickupLocation : 
                 (dropoffLocation && dropoffLocation[0] !== null && dropoffLocation[1] !== null) ? dropoffLocation : 
                 defaultCenter;

  return (
    <MapContainer center={center} zoom={13} style={{ height: '400px', width: '100%' }}>
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {pickupLocation && pickupLocation[0] !== null && pickupLocation[1] !== null && (
        <Marker position={pickupLocation} />
      )}
      {dropoffLocation && dropoffLocation[0] !== null && dropoffLocation[1] !== null && (
        <Marker position={dropoffLocation} />
      )}
      <MapUpdater center={center} />
    </MapContainer>
  );
};

export default MapComponent;