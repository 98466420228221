import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser } from '../../context/UserContext';
import './Header.css';

const Header = () => {
  const { isAuthenticated, logout } = useUser();
  const navigate = useNavigate();

  const handleLogout = async () => {
    await logout();
    navigate('/');
  };

  return (
    <header className="Header">
      <div className="container">
        <Link to="/" className="logo">Global</Link>
        <nav className="nav">
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/ship">Ship</Link></li>
            <li><Link to="/track">Track</Link></li>
            <li><Link to="/About">About Global Shipping</Link></li>
            {isAuthenticated && <li><Link to="/control-panel">Control Panel</Link></li>}
          </ul>
        </nav>
        <div className="actions">
          <select className="language-select">
            <option value="en">English</option>
          </select>
          <div className="user-actions">
            {isAuthenticated ? (
              <>
                <button onClick={handleLogout} className="btn secondary">Logout</button>
              </>
            ) : (
              <>
                <Link to="/signup" className="btn secondary">Register</Link>
                <Link to="/login" className="btn primary">Login</Link>
              </>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;