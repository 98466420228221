import React, { useState, useEffect } from 'react';
import './OrderCreation.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faMapMarkerAlt, faBox, faCreditCard, faCheckCircle, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import SenderDetails from './SenderDetails/SenderDetails';
import RecipientDetails from './RecipientDetails/RecipientDetails';
import PickupLocationForm from './PickupLocationForm/PickupLocationForm';
import DropoffLocationForm from './DropoffLocationForm/DropoffLocationForm';
import ShipmentDetails from './ShipmentDetails/ShipmentDetails';
import BillingDetails from './BillingDetails/BillingDetails';
import OrderConfirmation from './OrderConfirmation/OrderConfirmation';
import ErrorSummary from './ErrorSummary/ErrorSummary';
import ProgressBar from './ProgressBar/ProgressBar';
import NavigationButtons from './NavigationButtons/NavigationButtons';
import InstructionsPanel from './InstructionsPanel/InstructionsPanel';
import { validateSenderDetails, validateRecipientDetails, validateLocation, validateShipmentDetails, validateBillingDetails } from './validationUtils';
import { orderApi } from '../../api/api';

const steps = [
  'Sender Details',
  'Recipient Details',
  'Pick-up Location',
  'Drop-off Location',
  'Shipment Details',
  'Billing',
  'Confirmation'
];

const instructions = [
  { icon: faUser, text: "Enter the sender's full name, email, phone number, ID type, and upload an ID picture. This information is crucial for communication and tracking purposes." },
  { icon: faUser, text: "Provide the recipient's full name, email, phone number, ID type, and upload an ID picture. Ensure accuracy to avoid delivery issues." },
  { icon: faMapMarkerAlt, text: "Specify the exact pick-up location, including address, city, and country. This is where the package will be collected." },
  { icon: faMapMarkerAlt, text: "Enter the precise drop-off location, including address, city, and country. This is the final destination of your package." },
  { icon: faBox, text: "Describe your shipment, including its name, weight, and dimensions. Accurate details ensure proper handling and pricing." },
  { icon: faCreditCard, text: "Review the calculated amount and enter your payment details. We ensure secure processing of your financial information." },
  { icon: faCheckCircle, text: "Review all the information you've entered. Make sure everything is correct before submitting your order." }
];

const emptyLocation = {
  useDetailedAddress: true,
  country: '',
  shortAddress: '',
  streetName: '',
  buildingNumber: '',
  city: '',
  postalCode: '',
  additionalNumber: '',
  district: '',
  latitude: 40.7128, 
  longitude: -74.0060, 
};

const OrderCreation = ({ onOrderCreated, onCancel }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [orderData, setOrderData] = useState({
    senderDetails: {
      firstName: '',
      lastName: '',
      email: '',
      PhoneNumber: '',
      idType: '',
      idPicture: null
    },
    recipientDetails: {
      firstName: '',
      lastName: '',
      email: '',
      PhoneNumber: '',
      idType: '',
      idPicture: null
    },
    pickupLocation: { ...emptyLocation },
    dropoffLocation: { ...emptyLocation },
    shipmentDetails: {
      name: '',
      weight: '',
      length: '',
      width: '',
      height: '',
      description: ''
    },
    billingDetails: {
      cardNumber: '',
      cardHolderName: '',
      expirationDate: '',
      cvv: '',
      amount: 0
    }
  });
  const [errors, setErrors] = useState({});
  const [images, setImages] = useState([]);
  const [calculatedAmount, setCalculatedAmount] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (currentStep === 5) {
      const calculatedAmount = Math.floor(Math.random() * 1000) + 50;
      setCalculatedAmount(calculatedAmount);
      setOrderData(prevData => ({
        ...prevData,
        billingDetails: {
          ...prevData.billingDetails,
          amount: calculatedAmount
        }
      }));
    }
  }, [currentStep]);

  const validateStep = () => {
    let newErrors = {};
    switch (currentStep) {
      case 0:
        newErrors = validateSenderDetails(orderData.senderDetails);
        break;
      case 1:
        newErrors = validateRecipientDetails(orderData.recipientDetails);
        break;
      case 2:
        newErrors = validateLocation(orderData.pickupLocation, 'pickup');
        break;
      case 3:
        newErrors = validateLocation(orderData.dropoffLocation, 'dropoff');
        if (orderData.pickupLocation.city === orderData.dropoffLocation.city) {
          newErrors.locationState = "Pickup and dropoff locations must be in different cities.";
        }
        break;
      case 4:
        newErrors = validateShipmentDetails(orderData.shipmentDetails);
        break;
      case 5:
        newErrors = validateBillingDetails(orderData.billingDetails);
        break;
      default:
        break;
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const handleNext = () => {
    if (true) {
      if (currentStep < steps.length - 1) {
        setCurrentStep(currentStep + 1);
        scrollToTop();
      } else {
        handleSubmit();
      }
    }
  };

  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
      scrollToTop();
    }
  };

  const handleSubmit = async () => {
    if (window.confirm('Are you sure you want to submit this order?')) {
      setIsSubmitting(true);
      try {
        console.log('Submitting order data:', JSON.stringify(orderData, null, 2));
        const response = await orderApi.createOrder(orderData);
        onOrderCreated(response.data);
      } catch (error) {
        console.error('Error creating order:', error);
        setErrors({ submit: 'Failed to create order. Please try again.' });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleInputChange = (step, field, value) => {
    setOrderData(prevData => ({
      ...prevData,
      [step]: {
        ...prevData[step],
        [field]: value,
      }
    }));
  };

  const handlePickupLocationChange = (field, value) => {
    setOrderData(prevData => ({
      ...prevData,
      pickupLocation: {
        ...prevData.pickupLocation,
        [field]: value,
      }
    }));
  };

  const handleDropoffLocationChange = (field, value) => {
    setOrderData(prevData => ({
      ...prevData,
      dropoffLocation: {
        ...prevData.dropoffLocation,
        [field]: value,
      }
    }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    if (files.length > 5) {
      setErrors(prevErrors => ({...prevErrors, imageUpload: 'You can only upload up to 5 images.'}));
      return;
    }
    setImages(files);
    setErrors(prevErrors => ({...prevErrors, imageUpload: null}));
  };

  const renderStepContent = () => {
    let stepErrors = {};
    switch (currentStep) {
      case 0:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('sender')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {});
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <SenderDetails
              senderDetails={orderData.senderDetails}
              handleInputChange={(field, value) => handleInputChange('senderDetails', field, value)}
              errors={errors}
            />
          </>
        );
      case 1:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('recipient')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {});
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <RecipientDetails
              recipientDetails={orderData.recipientDetails}
              handleInputChange={(field, value) => handleInputChange('recipientDetails', field, value)}
              errors={errors}
            />
          </>
        );
      case 2:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('pickup')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {});
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <PickupLocationForm 
              location={orderData.pickupLocation}
              onLocationChange={handlePickupLocationChange}
              errors={errors}
            />
          </>
        );
      case 3:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('dropoff') || key === 'locationState').reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {});
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <DropoffLocationForm 
              location={orderData.dropoffLocation}
              onLocationChange={handleDropoffLocationChange}
              errors={errors}
            />
          </>
        );
      case 4:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('shipment')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {});
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <ShipmentDetails
              shipmentDetails={orderData.shipmentDetails}
              handleInputChange={(field, value) => handleInputChange('shipmentDetails', field, value)}
              errors={errors}
              handleImageUpload={handleImageUpload}
              images={images}
            />
          </>
        );
      case 5:
        stepErrors = Object.keys(errors).filter(key => key.startsWith('billing')).reduce((obj, key) => {
          obj[key] = errors[key];
          return obj;
        }, {});
        return (
          <>
            <ErrorSummary errors={stepErrors} />
            <BillingDetails
              billingDetails={orderData.billingDetails}
              handleInputChange={(field, value) => handleInputChange('billingDetails', field, value)}
              errors={errors}
              calculatedAmount={calculatedAmount}
            />
          </>
        );
      case 6:
        return <OrderConfirmation orderData={orderData} calculatedAmount={calculatedAmount} />;
      default:
        return null;
    }
  };

  return (
    <div className="OrderCreation">
      <button className="cancel-btn" onClick={onCancel}>
        <FontAwesomeIcon icon={faArrowLeft} /> Cancel
      </button>
      <h2>Create New Order</h2>
      <ProgressBar steps={steps} currentStep={currentStep} />
      <div className="content-wrapper">
        <div className="form-content">
          {renderStepContent()}
        </div>
        <div className="instructions-panel">
          <InstructionsPanel instruction={instructions[currentStep]} />
        </div>
      </div>
      <NavigationButtons
        currentStep={currentStep}
        totalSteps={steps.length}
        handlePrevious={handlePrevious}
        handleNext={handleNext}
        handleSubmit={handleSubmit}
        isSubmitting={isSubmitting}
      />
    </div>
  );
};

export default OrderCreation;