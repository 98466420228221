import React, { useRef } from 'react';
import './ShipmentDetails.css';

const ShipmentDetails = ({ shipmentDetails, handleInputChange, errors, handleImageUpload, images }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = (e) => {
    handleImageUpload(e);
    if (errors.imageUpload) {
      fileInputRef.current.value = ''; // Clear the file input if there's an error
    }
  };

  return (
    <div className="ShipmentDetails">
      <h3>Shipment Details</h3>
      <input
        type="text"
        placeholder="Shipment Name"
        value={shipmentDetails.name || ''}
        onChange={(e) => handleInputChange('name', e.target.value)}
      />
      {errors.shipmentName && <span className="error">{errors.shipmentName}</span>}
      <textarea
        placeholder="Shipment Description"
        value={shipmentDetails.description || ''}
        onChange={(e) => handleInputChange('description', e.target.value)}
      />
      <input
        type="number"
        placeholder="Weight (kg)"
        value={shipmentDetails.weight || ''}
        onChange={(e) => handleInputChange('weight', e.target.value)}
      />
      {errors.shipmentWeight && <span className="error">{errors.shipmentWeight}</span>}
      <div className="dimensions">
        <input
          type="number"
          placeholder="Length (cm)"
          value={shipmentDetails.length || ''}
          onChange={(e) => handleInputChange('length', e.target.value)}
        />
        <input
          type="number"
          placeholder="Width (cm)"
          value={shipmentDetails.width || ''}
          onChange={(e) => handleInputChange('width', e.target.value)}
        />
        <input
          type="number"
          placeholder="Height (cm)"
          value={shipmentDetails.height || ''}
          onChange={(e) => handleInputChange('height', e.target.value)}
        />
      </div>
      {(errors.shipmentLength || errors.shipmentWidth || errors.shipmentHeight) && 
        <span className="error">All dimensions are required</span>}
      <div className="image-upload">
        <label htmlFor="image-upload">Upload Images (Max 5)</label>
        <input
          id="image-upload"
          type="file"
          accept="image/*"
          multiple
          onChange={handleFileChange}
          ref={fileInputRef}
          max="5"
        />
        <p className="image-limit-message">You can upload up to 5 images</p>
        {errors.imageUpload && <span className="error">{errors.imageUpload}</span>}
      </div>
      <div className="image-preview">
        {images.map((image, index) => (
          <img key={index} src={URL.createObjectURL(image)} alt={`Preview ${index + 1}`} />
        ))}
      </div>
    </div>
  );
};

export default ShipmentDetails;