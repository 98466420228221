import React from 'react';
import { useSpring, animated } from 'react-spring';
import './OrderDetails.css';

const OrderStatus = {
  0: 'Pending',
  1: 'Processing',
  2: 'Shipped',
  3: 'InTransit',
  4: 'Delivered',
  5: 'Cancelled'
};

const OrderDetails = ({ order, onClose }) => {
  const animation = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(50px)' },
  });

  if (!order) return null;

  return (
    <animated.div className="OrderDetails" style={animation}>
      <div className="order-details-container">
        <div className="order-details-header">
          <h2>Order Details</h2>
          <button className="back-button" onClick={onClose}>
            <i className="fas fa-arrow-left"></i> Back to Orders
          </button>
        </div>
        <div className="order-details-content">
          <section className="order-info">
            <h3>Order Information</h3>
            <p><strong>Order ID:</strong> #{order.id}</p>
            <p><strong>Tracking Number:</strong> {order.trackingNumber}</p>
            <p><strong>Date:</strong> {new Date(order.createdAt).toLocaleDateString()}</p>
            <p><strong>Status:</strong> 
              <span className={`status-badge ${OrderStatus[order.status].toLowerCase()}`}>
                {OrderStatus[order.status]}
              </span>
            </p>
            <p><strong>Recipient:</strong> {`${order.recipientDetails.firstName} ${order.recipientDetails.lastName}`}</p>
            <p><strong>Amount:</strong> ${order.amount.toFixed(2)}</p>
          </section>
          
          <section className="shipment-info">
            <h3>Shipment Details</h3>
            <p><strong>Weight:</strong> {order.shipmentDetails.weight} kg</p>
            <p><strong>Size:</strong> {order.shipmentDetails.length}x{order.shipmentDetails.width}x{order.shipmentDetails.height} cm</p>
            <div className="address-container">
              <div className="origin-address">
                <h4>Origin Address</h4>
                <p>{`${order.pickupLocation.streetName}, ${order.pickupLocation.city}, ${order.pickupLocation.country}`}</p>
              </div>
              <div className="destination-address">
                <h4>Destination Address</h4>
                <p>{`${order.dropoffLocation.streetName}, ${order.dropoffLocation.city}, ${order.dropoffLocation.country}`}</p>
              </div>
            </div>
          </section>
          
          {order.trackingEvents && order.trackingEvents.length > 0 && (
            <section className="tracking-info">
              <h3>Tracking Information</h3>
              <div className="tracking-timeline">
                {order.trackingEvents.map((event, index) => (
                  <div key={index} className={`tracking-event ${event.status.toLowerCase().replace(/\s+/g, '-')}`}>
                    <div className="event-date">{new Date(event.timestamp).toLocaleString()}</div>
                    <div className="event-status">{event.status}</div>
                    <div className="event-location">{event.location}</div>
                    <div className="event-description">{event.description}</div>
                  </div>
                ))}
              </div>
            </section>
          )}
        </div>
      </div>
    </animated.div>
  );
};

export default OrderDetails;