import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Header from './components/Header/Header';
import Home from './pages/Home';
import Login from './components/Login/Login';
import Signup from './components/Signup/Signup';
import ControlPanel from './components/ControlPanel/ControlPanel';
import OrdersManagement from './components/OrdersManagement/OrdersManagement';
import AccountSection from './components/AccountSection/AccountSection';
import NotificationCenter from './components/NotificationCenter/NotificationCenter';
import { UserProvider } from './context/UserContext';
import './styles/global.css';

function App() {
  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Header />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/control-panel" element={<ControlPanel />}>
              <Route index element={<AccountSection />} />
              <Route path="orders" element={<OrdersManagement />} />
              <Route path="notifications" element={<NotificationCenter />} />
              <Route path="*" element={<ControlPanel />} />
            </Route>
            {/* Add other routes here */}
          </Routes>
          <ToastContainer
            position="top-right"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
