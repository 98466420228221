import React, { useState, useEffect } from 'react';
import { notificationApi } from '../../api/api';
import './NotificationCenter.css';

const NotificationCenter = () => {
  const [notifications, setNotifications] = useState([]);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const fetchNotifications = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await notificationApi.getNotifications();
      setNotifications(response.data);
    } catch (err) {
      setError('Failed to fetch notifications. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNotificationClick = async (notification) => {
    setSelectedNotification(notification);
    if (!notification.isRead) {
      try {
        await notificationApi.markAsRead(notification.id);
        const updatedNotifications = notifications.map(n =>
          n.id === notification.id ? { ...n, isRead: true } : n
        );
        setNotifications(updatedNotifications);
      } catch (err) {
        setError('Failed to mark notification as read. Please try again.');
      }
    }
  };

  const handleCloseDetails = () => {
    setSelectedNotification(null);
  };

  const getNotificationIcon = (title) => {
    switch (title.toLowerCase()) {
      case 'order shipped':
        return 'shipping-fast';
      case 'delivery update':
        return 'truck';
      case 'new promotion':
        return 'tag';
      default:
        return 'bell';
    }
  };

  return (
    <div className="NotificationCenter NotificationCenterComponent">
      <h2>Notification Center</h2>
      {error && <div className="error">{error}</div>}
      {isLoading ? (
        <div className="loading">Loading notifications...</div>
      ) : (
        <div className="notifications-list">
          {notifications.map(notification => (
            <div
              key={notification.id}
              className={`notification-item ${notification.isRead ? 'read' : 'unread'}`}
              onClick={() => handleNotificationClick(notification)}
            >
              <div className="notification-icon">
                <i className={`fas fa-${getNotificationIcon(notification.title)}`}></i>
              </div>
              <div className="notification-content">
                <h4>{notification.title}</h4>
                <p>{notification.message}</p>
                <span className="notification-date">{new Date(notification.createdAt).toLocaleString()}</span>
              </div>
            </div>
          ))}
        </div>
      )}

      {selectedNotification && (
        <div className="notification-details">
          <h3>{selectedNotification.title}</h3>
          <p>{selectedNotification.message}</p>
          <p><strong>Date:</strong> {new Date(selectedNotification.createdAt).toLocaleString()}</p>
          {selectedNotification.orderId && (
            <p><strong>Related Order ID:</strong> {selectedNotification.orderId}</p>
          )}
          <button onClick={handleCloseDetails}>Close</button>
        </div>
      )}
    </div>
  );
};

export default NotificationCenter;