import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaChartLine, FaBox, FaShippingFast, FaBell, FaUser, FaCog } from 'react-icons/fa';
import './Sidebar.css';
import armadaLogo from '../../assets/images/sidebar-logo.svg';

const Sidebar = ({ isCollapsed, toggleSidebar, activeTab, setActiveTab }) => {
  const sidebarItems = [
    { icon: <FaChartLine />, title: 'Dashboard', id: 'dashboard' },
    { icon: <FaBox />, title: 'Orders', id: 'orders' },
    { icon: <FaShippingFast />, title: 'Tracking', id: 'tracking' },
    { icon: <FaBell />, title: 'Notifications', id: 'notifications' },
    { icon: <FaUser />, title: 'Account', id: 'account' },
    { icon: <FaCog />, title: 'Settings', id: 'settings' },
  ];

  return (
    <aside className={`Sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <div className="sidebar-header">
        <img 
          src={armadaLogo} 
          alt="Armada Logo" 
          className="logo" 
          onClick={toggleSidebar}
        />
      </div>
      <nav>
        <ul>
          {sidebarItems.map((item) => (
            <li key={item.id}>
              <NavLink
                to={`/control-panel/${item.id}`}
                className={({ isActive }) => isActive ? 'active' : ''}
                onClick={() => setActiveTab(item.id)}
              >
                <span className="icon">{item.icon}</span>
                <span className="title">{item.title}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </aside>
  );
};

export default Sidebar;