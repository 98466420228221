import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './ControlPanel.css';
import { FaBox, FaShippingFast, FaClipboardList, FaSpinner } from 'react-icons/fa';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Sidebar from '../Sidebar/Sidebar';
import NotificationCenter from '../NotificationCenter/NotificationCenter';
import OrdersManagement from '../OrdersManagement/OrdersManagement';
import TrackingCenter from '../TrackingCenter/TrackingCenter';
import AccountSection from '../AccountSection/AccountSection';
import { useUser } from '../../context/UserContext';

ChartJS.register(ArcElement, Tooltip, Legend);

const ControlPanel = () => {
  const { user } = useUser();
  const [isSidebarCollapsed, setIsSidebarCollapsed] = useState(false);
  const [activeTab, setActiveTab] = useState('dashboard');
  const [animateCards, setAnimateCards] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadData = async () => {
      try {
        // Simulate data loading
        // await new Promise(resolve => setTimeout(resolve, 0000));
        setAnimateCards(true);
        setIsLoading(false);
      } catch (err) {
        setError('Failed to load data. Please try again.');
        setIsLoading(false);
      }
    };

    loadData();
  }, []);

  const toggleSidebar = () => {
    setIsSidebarCollapsed(!isSidebarCollapsed);
  };

  const quickLinks = [
    { icon: <FaBox />, title: 'Create Order', link: '/create-order', color: 'var(--primary-color)' },
    { icon: <FaShippingFast />, title: 'Track Shipment', link: '/track-shipment', color: 'var(--secondary-color)' },
    { icon: <FaClipboardList />, title: 'Manage Orders', link: '/manage-orders', color: '#e74c3c' },
  ];

  const orderStatusData = [
    { label: 'In Progress', count: 5, color: 'var(--primary-color)' },
    { label: 'Shipped', count: 12, color: 'var(--secondary-color)' },
    { label: 'Delivered', count: 8, color: '#e74c3c' },
  ];

  const chartData = {
    labels: orderStatusData.map(item => item.label),
    datasets: [
      {
        data: orderStatusData.map(item => item.count),
        backgroundColor: orderStatusData.map(item => item.color),
        hoverBackgroundColor: orderStatusData.map(item => item.color),
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.label || '';
            const value = context.parsed || 0;
            const total = context.dataset.data.reduce((acc, data) => acc + data, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${label}: ${value} (${percentage}%)`;
          },
        },
      },
    },
    animation: {
      animateScale: true,
      animateRotate: true
    }
  };

  const renderDashboard = () => (
    <div className="dashboard-default">
      <header className="welcome-banner">
        <div className="welcome-content">
          <h1>Welcome back,</h1>
          <h2 className="user-name">{user?.firstName || 'User'}</h2>
        </div>
        <div className="user-profile">
          {user?.profilePicture && <img src={user.profilePicture} alt={user?.firstName || ''} />}
          <span>{user?.email || ''}</span>
        </div>
      </header>
      <section className={`quick-links ${animateCards ? 'animate' : ''}`}>
        <h2>Quick Links</h2>
        <div className="cards">
          {quickLinks.map((link, index) => (
            <Link to={link.link} key={index} className="card" style={{ '--card-color': link.color }}>
              {link.icon}
              <h3>{link.title}</h3>
            </Link>
          ))}
        </div>
      </section>
      <section className="activity-feed">
        <h2>Recent Activity</h2>
        <ul>
          <li>New order #12345 created</li>
          <li>Shipment #54321 delivered</li>
          <li>Customer feedback received for order #67890</li>
        </ul>
      </section>
      <section className="order-status-chart">
        <h2>Order Status</h2>
        <div className="chart-container">
          <div className="chart-wrapper">
            <Doughnut data={chartData} options={chartOptions} />
          </div>
          <div className="status-labels">
            {orderStatusData.map((item, index) => (
              <div key={index} className="status-label-card" style={{ '--label-color': item.color }}>
                <div className="status-dot"></div>
                <div className="status-info">
                  <span className="status-text">{item.label}</span>
                  <span className="status-count">{item.count}</span>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );

  const renderActiveSection = () => {
    if (isLoading) {
      return (
        <div className="loading-spinner">
          <FaSpinner className="spinner" />
          <p>Loading...</p>
        </div>
      );
    }

    if (error) {
      return (
        <div className="error-message">
          <p>Error: {error}</p>
          <button onClick={() => window.location.reload()}>Retry</button>
        </div>
      );
    }

    switch (activeTab) {
      case 'orders':
        return <OrdersManagement />;
      case 'tracking':
        return <TrackingCenter />;
      case 'notifications':
        return <NotificationCenter />;
      case 'account':
        return <AccountSection user={user} />;
      default:
        return renderDashboard();
    }
  };

  return (
    <div className={`ControlPanel ${isSidebarCollapsed ? 'sidebar-collapsed' : ''}`}>
      <Sidebar
        isCollapsed={isSidebarCollapsed}
        toggleSidebar={toggleSidebar}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
      />
      <main className={`dashboard ${activeTab !== 'dashboard' ? 'full-width' : ''}`}>
        <div className="dashboard-content">
          {renderActiveSection()}
        </div>
      </main>
    </div>
  );
};

export default ControlPanel;