import React from 'react';
import './OrderConfirmation.css';

const OrderConfirmation = ({ orderData, calculatedAmount }) => {
  const formatAddress = (location) => {
    if (location.useDetailedAddress) {
      return `${location.streetName} ${location.buildingNumber}, ${location.district}, ${location.city}, ${location.postalCode}, ${location.country}`;
    } else {
      return `${location.shortAddress}, ${location.city}, ${location.country}`;
    }
  };

  return (
    <div className="OrderConfirmation">
      <h3>Order Confirmation</h3>
      <p>Please review your order details before submitting.</p>
      <div className="order-summary">
        <h4>Sender</h4>
        <p>{orderData.senderDetails.firstName} {orderData.senderDetails.lastName}</p>
        <p>{orderData.senderDetails.email}</p>
        <p>{orderData.senderDetails.phone}</p>
        <p>ID Type: {orderData.senderDetails.idType}</p>
        {orderData.senderDetails.idPicture && <p>ID Picture: Uploaded</p>}

        <h4>Recipient</h4>
        <p>{orderData.recipientDetails.firstName} {orderData.recipientDetails.lastName}</p>
        <p>{orderData.recipientDetails.email}</p>
        <p>{orderData.recipientDetails.phone}</p>
        <p>ID Type: {orderData.recipientDetails.idType}</p>
        {orderData.recipientDetails.idPicture && <p>ID Picture: Uploaded</p>}

        <h4>Pick-up Location</h4>
        <p>{formatAddress(orderData.pickupLocation)}</p>

        <h4>Drop-off Location</h4>
        <p>{formatAddress(orderData.dropoffLocation)}</p>

        <h4>Shipment Details</h4>
        <p>Name: {orderData.shipmentDetails.name}</p>
        <p>Description: {orderData.shipmentDetails.description}</p>
        <p>Weight: {orderData.shipmentDetails.weight} kg</p>
        <p>Dimensions: {orderData.shipmentDetails.length} x {orderData.shipmentDetails.width} x {orderData.shipmentDetails.height} cm (L x W x H)</p>

        <h4>Billing</h4>
        <p>Total Amount: ${orderData.billingDetails.amount.toFixed(2)}</p>
      </div>
    </div>
  );
};

export default OrderConfirmation;