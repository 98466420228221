import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './InstructionsPanel.css';

const InstructionsPanel = ({ instruction }) => {
  return (
    <div className="InstructionsPanel">
      <h3>Instructions</h3>
      <div className="instruction-content">
        <FontAwesomeIcon icon={instruction.icon} className="instruction-icon" />
        <p>{instruction.text}</p>
      </div>
    </div>
  );
};

export default InstructionsPanel;