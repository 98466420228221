import React, { useState } from 'react';
import './Hero.css';

const Hero = () => {
  const [activeTab, setActiveTab] = useState('track');
  const [trackingNumber, setTrackingNumber] = useState('');
  const [shipmentDetails, setShipmentDetails] = useState({
    from: '',
    to: '',
    weight: '',
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    if (activeTab === 'track') {
      console.log('Tracking number:', trackingNumber);
    } else {
      console.log('Shipment details:', shipmentDetails);
    }
  };

  return (
    <section className="Hero">
      <div className="content">
        <h1>Fast, Reliable Shipping Worldwide</h1>
        <p>Servicing over 220 countries and territories</p>
        <div className="form-container">
          <div className="form-tabs">
            <button
              className={`tab-btn ${activeTab === 'track' ? 'active' : ''}`}
              onClick={() => setActiveTab('track')}
            >
              Track
            </button>
            <button
              className={`tab-btn ${activeTab === 'ship' ? 'active' : ''}`}
              onClick={() => setActiveTab('ship')}
            >
              Ship
            </button>
          </div>
          <form onSubmit={handleSubmit} className="form">
            {activeTab === 'track' ? (
              <div className="form-group">
                <label htmlFor="tracking-number">Tracking Number</label>
                <input
                  id="tracking-number"
                  type="text"
                  placeholder="Enter tracking number"
                  value={trackingNumber}
                  onChange={(e) => setTrackingNumber(e.target.value)}
                  className="form-input"
                />
              </div>
            ) : (
              <>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="from">From</label>
                    <input
                      id="from"
                      type="text"
                      placeholder="City or Postal Code"
                      value={shipmentDetails.from}
                      onChange={(e) => setShipmentDetails({...shipmentDetails, from: e.target.value})}
                      className="form-input"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="to">To</label>
                    <input
                      id="to"
                      type="text"
                      placeholder="City or Postal Code"
                      value={shipmentDetails.to}
                      onChange={(e) => setShipmentDetails({...shipmentDetails, to: e.target.value})}
                      className="form-input"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="weight">Weight (kg)</label>
                  <input
                    id="weight"
                    type="text"
                    placeholder="Enter weight"
                    value={shipmentDetails.weight}
                    onChange={(e) => setShipmentDetails({...shipmentDetails, weight: e.target.value})}
                    className="form-input"
                  />
                </div>
              </>
            )}
            <button type="submit" className="btn btn-primary">
              {activeTab === 'track' ? 'Track Shipment' : 'Get Quote'}
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Hero;