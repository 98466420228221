import React from 'react';
import './SubSection.css';
import subsectionImage from '../../assets/images/subsection2.png';

const SubSection = () => {
  return (
    <section className="SubSection">
      <div className="container">
        <div className="subsection-container">
          <div className="subsection-text">
            <h2 className="subsection-title">Welcome to the New Global Experience</h2>
            <div className="subsection-description">
              <p>Experience seamless global shipping with our innovative platform. From packing to delivery, we've streamlined every step of the process to ensure your packages reach their destination efficiently and securely.</p>
              <p>Our advanced tracking system keeps you informed at every stage, while our worldwide network ensures rapid and reliable delivery to any corner of the globe.</p>
            </div>
          </div>
          <div className="subsection-image">
            <img src={subsectionImage} alt="Global Shipping" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default SubSection;