import React, { createContext, useState, useContext, useEffect } from 'react';
import { authApi, notificationApi } from '../api/api';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      fetchUnreadNotificationsCount();
    }
  }, [isAuthenticated]);

  const checkAuthStatus = async () => {
    try {
      const response = await authApi.checkAuth();
      if (response.data.isAuthenticated) {
        setIsAuthenticated(true);
        setUser(response.data.user);
      } else {
        setIsAuthenticated(false);
        setUser(null);
      }
    } catch (error) {
      console.error('Error checking authentication status:', error);
      setIsAuthenticated(false);
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const fetchUnreadNotificationsCount = async () => {
    try {
      const response = await notificationApi.getUnreadCount();
      setUnreadNotificationsCount(response.data.count);
    } catch (error) {
      console.error('Error fetching unread notifications count:', error);
    }
  };

  const login = async (userData) => {
    setIsAuthenticated(true);
    setUser(userData);
    await fetchUnreadNotificationsCount();
  };

  const logout = async () => {
    try {
      await authApi.logout();
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setIsAuthenticated(false);
      setUser(null);
      setUnreadNotificationsCount(0);
    }
  };

  const signup = async (userData) => {
    try {
      const response = await authApi.signup(userData);
      return response.data;
    } catch (error) {
      console.error('Error during signup:', error);
      throw error;
    }
  };

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    user,
    setUser,
    login,
    logout,
    signup,
    loading,
    unreadNotificationsCount,
    fetchUnreadNotificationsCount
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};