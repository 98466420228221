import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useUser } from '../../context/UserContext';
import './Signup.css';

const countries = [
  'United States', 'Canada', 'United Kingdom', 'Australia', 'Germany', 'France', 'Japan', 'China', 'India', 'Brazil'
  // Add more countries as needed
];

const Signup = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phoneNumber: '',
    address: {
      streetAddress: '',
      city: '',
      state: '',
      postalCode: '',
      country: ''
    }
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { signup } = useUser();

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setFormData(prevState => ({
        ...prevState,
        [parent]: {
          ...prevState[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await signup(formData);
      toast.success('Account created successfully!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      navigate('/login');
    } catch (err) {
      setError(err.message || 'An error occurred during signup. Please try again.');
      toast.error('Signup failed. Please try again.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="Signup">
      <div className="signup-container">
        <div className="signup-content">
          <div className="signup-form-section">
            <h2>Create Your Account</h2>
            <p className="signup-subtitle">Join us and experience the best in global logistics and shipping solutions!</p>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit} className="signup-form">
              <div className="form-section">
                <h3>Personal Information</h3>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="firstName">First Name</label>
                    <input type="text" id="firstName" name="firstName" value={formData.firstName} onChange={handleChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input type="text" id="lastName" name="lastName" value={formData.lastName} onChange={handleChange} required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password">Password</label>
                    <input type="password" id="password" name="password" value={formData.password} onChange={handleChange} required />
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="phoneNumber">Phone Number</label>
                  <input type="tel" id="phoneNumber" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} required />
                </div>
              </div>
              <div className="form-section">
                <h3>Address Information</h3>
                <div className="form-group">
                  <label htmlFor="address.streetAddress">Address</label>
                  <input type="text" id="address.streetAddress" name="address.streetAddress" value={formData.address.streetAddress} onChange={handleChange} required />
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="address.city">City</label>
                    <input type="text" id="address.city" name="address.city" value={formData.address.city} onChange={handleChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address.state">State/Province</label>
                    <input type="text" id="address.state" name="address.state" value={formData.address.state} onChange={handleChange} required />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="address.postalCode">Zip/Postal Code</label>
                    <input type="text" id="address.postalCode" name="address.postalCode" value={formData.address.postalCode} onChange={handleChange} required />
                  </div>
                  <div className="form-group">
                    <label htmlFor="address.country">Country</label>
                    <select id="address.country" name="address.country" value={formData.address.country} onChange={handleChange} required>
                      <option value="">Select a country</option>
                      {countries.map((country, index) => (
                        <option key={index} value={country}>{country}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              <button type="submit" className="signup-button" disabled={isLoading}>
                {isLoading ? 'Creating Account...' : 'Create Account'}
              </button>
            </form>
          </div>
          <div className="signup-instructions">
            <h3>How to Sign Up</h3>
            <ul>
              <li>
                <span className="step-number">1</span>
                <p>Fill in your personal information accurately</p>
              </li>
              <li>
                <span className="step-number">2</span>
                <p>Provide a valid email address for account verification</p>
              </li>
              <li>
                <span className="step-number">3</span>
                <p>Choose a strong password to secure your account</p>
              </li>
              <li>
                <span className="step-number">4</span>
                <p>Enter your complete address for shipping purposes</p>
              </li>
              <li>
                <span className="step-number">5</span>
                <p>Select your country from the dropdown menu</p>
              </li>
              <li>
                <span className="step-number">6</span>
                <p>Review your information and click "Create Account"</p>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ocean">
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </div>
  );
};

export default Signup;