import React from 'react';
import './Services.css';
import oceanVideo from '../../assets/videos/global-shipping.mp4';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faShip, faLocationDot, faTruck } from '@fortawesome/free-solid-svg-icons';

const Services = () => {
  const services = [
    { 
      name: 'Pack', 
      description: "Our expert packing services ensure your items are securely wrapped and protected for their journey. We use high-quality materials and techniques to safeguard your shipments against damage during transit.",
      icon: faBox
    },
    { 
      name: 'Ship', 
      description: "Choose from our wide range of shipping options, including express, standard, and economy services. We offer competitive rates and flexible solutions for both domestic and international shipments.",
      icon: faShip
    },
    { 
      name: 'Track', 
      description: "Stay informed with our advanced tracking system. Get real-time updates on your shipment's location and estimated delivery time. Our user-friendly interface makes it easy to track multiple packages at once.",
      icon: faLocationDot
    },
    { 
      name: 'Receive', 
      description: "Experience hassle-free delivery with our convenient receiving options. Choose from home delivery, office drop-off, or pickup from one of our numerous service points. We ensure your package arrives safely and on time.",
      icon: faTruck
    }
  ];

  return (
    <section className="Services">
      <video autoPlay loop muted className="background-video">
        <source src={oceanVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div className="content">
        <h2>Comprehensive Shipping Solutions</h2>
        <p>Discover how Global handles your shipments with care and precision at every step of their journey.</p>
        
        <div className="steps">
          {services.map((service, index) => (
            <div key={index} className="card">
              <FontAwesomeIcon icon={service.icon} className="icon" />
              <h3>{service.name}</h3>
              <p>{service.description}</p>
            </div>
          ))}
        </div>

        <div className="shipping-info">
          <h3>Why Choose Global?</h3>
          <p>At Global, we pride ourselves on providing efficient, reliable, and secure shipping services. Our state-of-the-art tracking system ensures that you can monitor your package's journey from pickup to delivery. We offer a range of shipping options to meet your needs, whether you're sending documents, parcels, or large freight.</p>
          <p>Our global network of partners allows us to deliver to over 200 countries, making international shipping easier than ever. We understand that each shipment is unique, which is why we offer customized solutions for businesses and individuals alike. From express delivery for urgent packages to economical options for less time-sensitive items, we have a service that fits your requirements and budget.</p>
          <a href="/quote" className="cta-button">Get a Quote</a>
        </div>
      </div>
    </section>
  );
};

export default Services;