import React, { useState, useEffect, useRef } from 'react';
import './TrackingCenter.css';
import orderTrackingImage from '../../assets/images/order-tracking.jpeg';
import backgroundVideo from '../../assets/videos/ocean-video.mp4';
import { orderApi } from '../../api/api';

const TrackingCenter = () => {
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingDetails, setTrackingDetails] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const videoRef = useRef(null);

  const handleTrack = async () => {
    setError(null);
    setTrackingDetails(null);
    setIsLoading(true);

    try {
      const response = await orderApi.trackOrder(trackingNumber);
      console.log('API Response:', response);
      setTrackingDetails(response.data);
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setError('Order not found. Please check the tracking number and try again.');
      } else {
        setError('An error occurred while fetching the tracking information. Please try again later.');
        console.error('Error fetching tracking data:', err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const formatDateTime = (date, time) => {
    return `${date} ${time}`;
  };

  const handleVideoError = (e) => {
    console.error('Error loading video:', e);
    setError('There was an issue loading the background video.');
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        console.error('Error playing video:', error);
      });
    }
  }, []);

  return (
    <div className="tracking-center-component">
      <video 
        ref={videoRef}
        autoPlay 
        loop 
        muted 
        playsInline
        className="background-video"
        onError={handleVideoError}
      >
        <source src={backgroundVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      
      <div className="tracking-content">
        <div className="left-section">
          <img src={orderTrackingImage} alt="Order Tracking" className="tracking-image" />
          <div className="info-container">
            <p>Experience the power of our advanced tracking system. Stay informed about your shipment's journey every step of the way.</p>
          </div>
        </div>
        
        <div className="right-section">
          <div className="tracking-form">
            <h2>Track Your Shipment</h2>
            <div className="input-group">
              <input
                type="text"
                value={trackingNumber}
                onChange={(e) => setTrackingNumber(e.target.value)}
                placeholder="Enter tracking number"
              />
              <button onClick={handleTrack} disabled={isLoading}>
                {isLoading ? 'Tracking...' : 'Track'}
              </button>
            </div>
            
            {error && (
              <div className="error-message">
                <p>{error}</p>
              </div>
            )}
          </div>

          {trackingDetails && (
            <div className="tracking-details">
              <h3>Tracking Details</h3>
              <div className="detail-grid">
                <div className="detail-item">
                  <span className="detail-label">Status</span>
                  <span className="detail-value">{trackingDetails.status || 'N/A'}</span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Origin</span>
                  <span className="detail-value">{trackingDetails.origin || 'N/A'}</span>
                </div>
                <div className="detail-item">
                  <span className="detail-label">Destination</span>
                  <span className="detail-value">{trackingDetails.destination || 'N/A'}</span>
                </div>
              </div>
              <h4>Tracking Events</h4>
              {trackingDetails.events && trackingDetails.events.length > 0 ? (
                <ul className="event-list">
                  {trackingDetails.events.map((event, index) => (
                    <li key={index} className="event-item">
                      <div className="event-icon"></div>
                      <div className="event-details">
                        <p className="event-datetime">{formatDateTime(event.date, event.time)}</p>
                        <p className="event-location">{event.location || 'N/A'}</p>
                        <p className="event-status">{event.status || 'N/A'}</p>
                        <p className="event-description">{event.description || 'N/A'}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p>No tracking events available.</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TrackingCenter;