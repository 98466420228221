import React, { useEffect, useCallback } from 'react';
import './PickupLocationForm.css';
import MapComponent from '../../MapComponent';
import { geocodingApi } from '../../../api/api';

const PickupLocationForm = ({ location, onLocationChange, errors, otherLocation }) => {
  const geocodeAddress = useCallback(async () => {
    const address = `${location.streetName} ${location.buildingNumber}, ${location.city}, ${location.country}`;
    try {
      const response = await geocodingApi.geocodeAddress(address);
      if (response.data) {
        onLocationChange('latitude', response.data.latitude);
        onLocationChange('longitude', response.data.longitude);
      }
    } catch (error) {
      console.error('Error geocoding address:', error);
    }
  }, [location.streetName, location.buildingNumber, location.city, location.country, onLocationChange]);

  useEffect(() => {
    if (location.country && location.city && location.streetName) {
      geocodeAddress();
    }
  }, [location.country, location.city, location.streetName, location.buildingNumber, geocodeAddress]);

  return (
    <div className="PickupLocationForm">
      <h3>Pick-up Location</h3>
      <select
        value={location.country || ''}
        onChange={(e) => onLocationChange('country', e.target.value)}
      >
        <option value="">Select Country</option>
        <option value="Kuwait">Kuwait</option>
        <option value="Saudi Arabia">Saudi Arabia</option>
        <option value="Bahrain">Bahrain</option>
      </select>
      {errors.pickupCountry && <span className="error">{errors.pickupCountry}</span>}
      
      {location.country === 'Saudi Arabia' && !location.useDetailedAddress && (
        <div>
          <input
            type="text"
            placeholder="Short Address"
            value={location.shortAddress || ''}
            onChange={(e) => onLocationChange('shortAddress', e.target.value)}
          />
        </div>
      )}
      
      {location.country === 'Saudi Arabia' && (
        <div className="checkbox-container">
          <input
            type="checkbox"
            id="pickupUseDetailedAddress"
            checked={location.useDetailedAddress || false}
            onChange={(e) => onLocationChange('useDetailedAddress', e.target.checked)}
            className="modern-checkbox"
          />
          <label htmlFor="pickupUseDetailedAddress">Use detailed address</label>
        </div>
      )}
      
      {(location.country !== 'Saudi Arabia' || location.useDetailedAddress) && (
        <div>
          <input
            type="text"
            placeholder="Street Name"
            value={location.streetName || ''}
            onChange={(e) => onLocationChange('streetName', e.target.value)}
          />
          <input
            type="text"
            placeholder="Building Number"
            value={location.buildingNumber || ''}
            onChange={(e) => onLocationChange('buildingNumber', e.target.value)}
          />
          <input
            type="text"
            placeholder="City"
            value={location.city || ''}
            onChange={(e) => onLocationChange('city', e.target.value)}
          />
          <input
            type="text"
            placeholder="Postal Code"
            value={location.postalCode || ''}
            onChange={(e) => onLocationChange('postalCode', e.target.value)}
          />
          {location.country === 'Saudi Arabia' && (
            <input
              type="text"
              placeholder="Additional Number"
              value={location.additionalNumber || ''}
              onChange={(e) => onLocationChange('additionalNumber', e.target.value)}
            />
          )}
          <input
            type="text"
            placeholder="District"
            value={location.district || ''}
            onChange={(e) => onLocationChange('district', e.target.value)}
          />
        </div>
      )}
      {errors.pickupAddress && <span className="error">{errors.pickupAddress}</span>}
      
      <div className="map-container">
        <MapComponent 
          pickupLocation={[location.latitude, location.longitude]}
          dropoffLocation={otherLocation}
        />
      </div>
    </div>
  );
};

export default PickupLocationForm;