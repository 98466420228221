import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://localhost:5000/api',
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true, // This ensures that cookies are sent with every request
});

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401) {
      // If unauthorized, redirect to login
      // window.location.href = '/login';
    }
    return Promise.reject(error);
  }
);

export const authApi = {
  login: (credentials) => api.post('/auth/login', credentials),
  signup: (userData) => api.post('/auth/signup', userData),
  logout: () => api.post('/auth/logout'),
  checkAuth: () => api.get('/auth/check'),
};

export const orderApi = {
  getOrders: () => api.get('/orders'),
  createOrder: (orderData) => api.post('/orders', orderData),
  getOrder: (id) => api.get(`/orders/${id}`),
  updateOrder: (id, orderData) => api.put(`/orders/${id}`, orderData),
  cancelOrder: (id) => api.delete(`/orders/${id}`),
  trackOrder: (trackingNumber) => api.get(`/orders/track/${trackingNumber}`),
};

export const notificationApi = {
  getNotifications: () => api.get('/notifications'),
  markAsRead: (id) => api.put(`/notifications/${id}/read`),
  getUnreadCount: () => api.get('/notifications/unread-count'),
};

export const accountApi = {
  getAccountInfo: () => api.get('/account'),
  updateAccountInfo: (accountData) => api.put('/account', accountData),
  changePassword: (passwordData) => api.put('/account/password', passwordData),
};

export const shipmentApi = {
  getShipment: (id) => api.get(`/shipments/${id}`),
  createShipment: (shipmentData) => api.post('/shipments', shipmentData),
  updateShipment: (id, shipmentData) => api.put(`/shipments/${id}`, shipmentData),
};

export const geocodingApi = {
  geocodeAddress: (address) => api.get(`/geocoding/geocode?address=${encodeURIComponent(address)}`),
};

export default api;