import React, { useState, useEffect, useCallback } from 'react';
import { useSpring, animated, useTransition, config } from 'react-spring';
import OrderDetails from '../OrderDetails/OrderDetails';
import OrderCreation from '../OrderCreation/OrderCreation';
import { orderApi } from '../../api/api';
import './OrdersManagement.css';

const OrderStatus = {
  0: 'Pending',
  1: 'Processing',
  2: 'Shipped',
  3: 'InTransit',
  4: 'Delivered',
  5: 'Cancelled'
};

const OrdersTable = ({ orders, onOrderClick }) => {
  const transitions = useTransition(orders, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    enter: { opacity: 1, transform: 'translateY(0)' },
    leave: { opacity: 0, transform: 'translateY(-20px)' },
    trail: 100,
  });

  return (
    <table className="order-management-table">
      <thead>
        <tr>
          <th>Date</th>
          <th>Tracking Number</th>
          <th>Recipient</th>
          <th>Amount ($)</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {transitions((style, order) => (
          <animated.tr key={order.id} style={style} onClick={() => onOrderClick(order)}>
            <td className="date">{new Date(order.createdAt).toLocaleDateString()}</td>
            <td className="tracking-number">{order.trackingNumber}</td>
            <td className="recipient">{`${order.recipientDetails.firstName} ${order.recipientDetails.lastName}`}</td>
            <td className="amount">{typeof order.amount === 'number' ? order.amount.toFixed(2) : parseFloat(order.amount).toFixed(2)}</td>
            <td>
              <span className={`status ${OrderStatus[order.status].toLowerCase()}`}>
                {OrderStatus[order.status]}
              </span>
            </td>
          </animated.tr>
        ))}
      </tbody>
    </table>
  );
};

const OrdersManagement = () => {
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [isCreatingOrder, setIsCreatingOrder] = useState(false);
  const [showOrderCreation, setShowOrderCreation] = useState(false);

  const fetchOrders = useCallback(async () => {
    setError(null);
    try {
      const response = await orderApi.getOrders();
      setOrders(response.data);
    } catch (err) {
      setError('Failed to fetch orders. Please try again later.');
    }
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
  };

  const handleCloseOrderDetails = () => {
    setSelectedOrder(null);
  };

  const handleCreateOrder = () => {
    setShowOrderCreation(true);
  };

  const handleOrderCreated = async (newOrder) => {
    try {
      setIsCreatingOrder(true);
      await orderApi.createOrder(newOrder);
      await fetchOrders(); // Refresh the orders list
      setShowOrderCreation(false);
    } catch (err) {
      setError('Failed to create order. Please try again.');
    } finally {
      setIsCreatingOrder(false);
    }
  };

  const handleCancelOrderCreation = () => {
    setShowOrderCreation(false);
  };

  const filteredOrders = orders.filter(order =>
    `${order.recipientDetails.firstName} ${order.recipientDetails.lastName}`.toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.trackingNumber.toLowerCase().includes(searchTerm.toLowerCase()) ||
    OrderStatus[order.status].toLowerCase().includes(searchTerm.toLowerCase()) ||
    new Date(order.createdAt).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase()) ||
    order.amount.toString().includes(searchTerm)
  );

  const contentProps = useSpring({
    opacity: showOrderCreation ? 0 : 1,
    transform: showOrderCreation ? 'translateY(-20px)' : 'translateY(0px)',
    display: showOrderCreation ? 'none' : 'block',
    config: config.gentle
  });

  const orderCreationProps = useSpring({
    opacity: showOrderCreation ? 1 : 0,
    transform: showOrderCreation ? 'translateY(0px)' : 'translateY(20px)',
    display: showOrderCreation ? 'block' : 'none',
    config: config.gentle
  });

  return (
    <div className="OrdersManagement">
      <h2>Orders Management</h2>
      <animated.div style={contentProps}>
        {!selectedOrder && (
          <>
            <div className="instruction-container">
              <div className="instruction-text">
                <h3>Create a New Order</h3>
                <p>Start your order process by creating a new entry. Click the button below to get started.</p>
              </div>
              <button 
                className="create-order-btn" 
                onClick={handleCreateOrder}
                disabled={isCreatingOrder}
              >
                {isCreatingOrder ? 'Creating...' : <><i className="fas fa-plus"></i> Create New Order</>}
              </button>
            </div>
            <div className="search-container">
              <i className="fas fa-search search-icon"></i>
              <input
                type="text"
                placeholder="Search orders..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="search-input"
              />
            </div>
            {error && <div className="error">{error}</div>}
            <div className="orders-container">
              <OrdersTable orders={filteredOrders} onOrderClick={handleOrderClick} />
            </div>
          </>
        )}
        {selectedOrder && (
          <OrderDetails order={selectedOrder} onClose={handleCloseOrderDetails} />
        )}
      </animated.div>
      <animated.div style={orderCreationProps}>
        <OrderCreation onOrderCreated={handleOrderCreated} onCancel={handleCancelOrderCreation} />
      </animated.div>
    </div>
  );
};

export default OrdersManagement;