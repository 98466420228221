import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  trackingNumber: '',
  status: 'idle',
};

export const shipmentSlice = createSlice({
  name: 'shipment',
  initialState,
  reducers: {
    setTrackingNumber: (state, action) => {
      state.trackingNumber = action.payload;
    },
    setStatus: (state, action) => {
      state.status = action.payload;
    },
  },
});

export const { setTrackingNumber, setStatus } = shipmentSlice.actions;

export default shipmentSlice.reducer;